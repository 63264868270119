import React from "react"
import { useInView } from "react-intersection-observer"
import { useSpring, animated, config, useTrail } from "@react-spring/web"

const isDebug = process.env.NODE_ENV === "development"
export function FadeIn({ children, threshold = 0, triggerOnce = true }) {
  const { ref, inView } = useInView({
    threshold,
    triggerOnce: isDebug ? false : triggerOnce,
  })
  const { opacity, y } = useSpring({
    opacity: inView ? 1 : 0,
    config: config.molasses,
  })

  return (
    <animated.div
      ref={ref}
      style={{
        opacity,
      }}
    >
      {children}
    </animated.div>
  )
}

export function FadeInBottom({ children, threshold = 0, triggerOnce = true }) {
  const { ref, inView } = useInView({
    threshold,
    triggerOnce: isDebug ? false : triggerOnce,
  })
  const { opacity, y } = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 100,
    config: config.slow,
  })

  return (
    <animated.div
      ref={ref}
      style={{
        opacity,
        transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
      }}
    >
      {children}
    </animated.div>
  )
}

export function FadeInBottomTrail({
  containerCss = {},
  itemCss = {},
  children,
  threshold = 0,
  triggerOnce = true,
}) {
  const items = React.Children.toArray(children)
  const { ref, inView } = useInView({
    threshold,
    triggerOnce: isDebug ? false : triggerOnce,
  })

  const trail = useTrail(items.length, {
    config: config.slow,
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 100,
  })

  return (
    <div css={containerCss} ref={ref}>
      {trail.map(({ y, opacity }, index) => {
        return (
          <animated.div
            key={index}
            css={itemCss}
            style={{
              opacity,
              transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
            }}
          >
            {items[index]}
          </animated.div>
        )
      })}
    </div>
  )
}

export function FadeInLeft({ children, threshold = 0, triggerOnce = true }) {
  const { ref, inView } = useInView({
    threshold,
    triggerOnce: isDebug ? false : triggerOnce,
  })
  const { opacity, x } = useSpring({
    opacity: inView ? 1 : 0,
    x: inView ? 0 : -100,
    config: config.slow,
  })

  return (
    <animated.div
      ref={ref}
      style={{
        opacity,
        transform: x.interpolate(x => `translate3d(${x}px, 0, 0)`),
      }}
    >
      {children}
    </animated.div>
  )
}
export function FadeInRight({ children, threshold = 0, triggerOnce = true }) {
  const { ref, inView } = useInView({
    threshold,
    triggerOnce: isDebug ? false : triggerOnce,
  })
  const { opacity, x } = useSpring({
    opacity: inView ? 1 : 0,
    x: inView ? 0 : 100,
    config: config.slow,
  })

  return (
    <animated.div
      ref={ref}
      style={{
        opacity,
        transform: x.interpolate(x => `translate3d(${x}px, 0, 0)`),
      }}
    >
      {children}
    </animated.div>
  )
}
