import { css } from "@emotion/react"

export const hideOnBigScreen = css({
  display: "initial",
  "@media(min-width: 720px)": {
    display: "none",
  },
})

export const showOnBigScreen = css({
  display: "none",
  "@media(min-width: 720px)": {
    display: "initial",
  },
})
