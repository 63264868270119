import React from "react"

const Flex = ({
  fd = "row",
  jc = "unset",
  ai = "unset",
  gap = "unset",
  fxw = "unset",
  css,
  ...rest
}) => (
  <div
    css={[
      {
        display: "flex",
        flexDirection: fd,
        justifyContent: jc,
        alignItems: ai,
        flexWrap: fxw,
        gap,
      },
      css,
    ]}
    {...rest}
  />
)

export default Flex
